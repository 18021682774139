<template>
  <v-tour name="tour" :steps="steps" :options="options" :callbacks="callbacks">
  </v-tour>
</template>

<script>
export default {
  name: "CatalogTourComponent",
  data() {
    return {
      callbacks: {
        onSkip: this.tourStepFinish,
        onFinish: this.tourStepFinish
      },
      options: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: "Cerrar",
          buttonPrevious: "Anterior",
          buttonNext: "Continuar",
          buttonStop: "Finalizar"
        }
      },
      steps: [
        {
          target: "#app",
          content: `
            <h5 class="font-bold">1/2</h5>
            <h6 class="text-color-secondary font-bold">Crea tu lista de productos o servicios</h6>
            <p class="m-0">Creando tu lista de items podras asignarlos luego a los documentos que emitas.</p>
          `
        },
        {
          target: "#app",
          content: `
            <h5 class="font-bold">2/2</h5>
            <h6 class="text-color-secondary font-bold">Crea las categorías para tus productos o servicios</h6>
            <p class="m-0">Debes inciar creando las categorias para poder asignarlas a tus productos.</p>
          `
        }
      ]
    };
  },
  computed: {},
  methods: {
    tourStepFinish() {
      const userConfigGeneral = {
        is_catalog_tour_completed: 1
      };
      this.$store.dispatch("userConfigGeneralUpdated", userConfigGeneral);
    }
  },
  mounted() {
    this.$tours["tour"].start();
  }
};
</script>

<style lang="scss" scoped></style>
