<template>
  <el-select
    v-model="orderBy"
    placeholder="Ordenar por"
    class="select-filter-search"
    :clearable="true"
    @change="onSelectOrderBy"
  >
    <el-option
      v-for="(option, index) in options"
      :key="index"
      :label="option.text"
      :value="option.value"
    ></el-option>
  </el-select>
</template>

<script>
export default {
  name: "SelectOrderByComponent",
  data: () => ({
    orderBy: null,
    options: [
      { value: "recent", text: "Más reciente" },
      { value: "old", text: "Más antiguo" },
      { value: "a-z", text: "A a Z" },
      { value: "z-a", text: "Z a A" }
    ]
  }),
  methods: {
    onSelectOrderBy() {
      this.$emit("onSelectOrderBy", { orderBy: this.orderBy });
    }
  }
};
</script>

<style lang="scss" scoped></style>
