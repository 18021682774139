<template>
  <div class="">
    <TopBarTitleComponent title="Movimientos" :showBackIcon="true">
      <template slot="right-area">
        <div class="d-flex justify-content-end align-items-center">
          <el-button
            class="btn-primary-custom mr-2"
            @click="showModal('inputs')"
          >
            <i class="el-icon-circle-plus"></i> Ingreso
          </el-button>
          <el-button type="danger" @click="showModal('outputs')">
            <i class="el-icon-remove"></i> Salida
          </el-button>
        </div>
      </template>
    </TopBarTitleComponent>

    <b-container fluid class="pb-4 px-lg-4">
      <section class="input-filters">
        <InputSearchComponent @onChangeSearch="onChangeSearch" />
        <SelectOrderByComponent @onSelectOrderBy="onSelectOrderBy" />
      </section>
      <template v-if="apiResponse.success">
        <template v-if="expandedProducts.length">
          <section class="table-items table-items-catalogue">
            <b-row class="table-items-header line-bottom">
              <b-col xs="12" sm="12" md="1">
                <h6 class="h6 ml-2">Código</h6>
              </b-col>
              <b-col xs="12" sm="12" md="4">
                <h6 class="h6">Nombre</h6>
              </b-col>
              <b-col xs="12" sm="12" md="2">
                <h6 class="h6">Establecimiento</h6>
              </b-col>
              <b-col xs="12" sm="12" md="1">
                <h6 class="h6">Stock</h6>
              </b-col>
              <b-col xs="12" sm="12" md="4">
                <h6 class="h6">Acciones</h6>
              </b-col>
            </b-row>
            <div
              class="card table-items-body"
              v-for="(product, index) in resultQueryProducts"
              :index="index"
              :key="index"
            >
              <div class="card-body table-items-content">
                <b-row>
                  <b-col xs="12" sm="12" md="1" class="table-col-item">
                    <span class="item-col-code font-semi-bold">
                      {{ product.code }}
                    </span>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" class="table-col-item">
                    <span class="product-name font-bold str-limit-space">
                      {{ product.name }}
                    </span>
                  </b-col>
                  <b-col xs="12" sm="12" md="2" class="table-col-item">
                    <span class="product-amount font-semi-bold">
                      {{ product.establishment }}
                    </span>
                  </b-col>
                  <b-col xs="12" sm="12" md="1" class="table-col-item">
                    <span class="product-amount font-semi-bold">
                      {{ product.quantity }}
                    </span>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" class="table-col-item">
                    <el-button-group>
                      <el-button
                        size="small"
                        type="primary"
                        @click="showModal('transfer', product)"
                      >
                        Trasladar
                      </el-button>
                      <el-button
                        size="small"
                        type="primary"
                        @click="showModal('details', product.details)"
                      >
                        Movimientos
                      </el-button>
                    </el-button-group>
                  </b-col>
                </b-row>
              </div>
            </div>

            <Pagination
              :pagination="pagination"
              @change="onChangePaginationPage"
            />
          </section>
        </template>
        <EmptyPage
          :media="emptyMedia2"
          text="Registras tus productos o servicios  y empieza a ofrecer a tus clientes."
          v-else
        />
      </template>
      <LoadingSkeletonTable v-else />
    </b-container>

    <WrapperCatalogTourComponent />

    <el-dialog
      :title="type === 'input' ? 'Ingresos' : 'Salidas'"
      :visible.sync="modalStatus"
    >
      <b-form @submit="onSubmit">
        <b-row>
          <b-col xs="12" sm="12" md="12">
            <b-form-group label="Producto:">
              <SelectProductsComponent
                :show-add="false"
                autocomplete
                @onSelectProduct="onSelectProduct"
              />
            </b-form-group>
            <p>
              <b>Producto Seleccionado:</b>
              {{ form?.user_business_product_name ?? "-" }}
            </p>
          </b-col>
          <b-col xs="12" sm="12" md="6">
            <b-form-group label="Cantidad:">
              <b-form-input
                v-model="form.quantity"
                type="number"
                min="0.00"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6">
            <b-form-group label="Establecimiento:">
              <b-form-select
                v-model="form.establishment_id"
                :options="stablishments"
                required
                value-field="id"
                text-field="name"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="12">
            <b-form-group label="Motivo traslado:">
              <b-form-input v-model="form.reason_transfer" required>
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="12" class="text-right">
            <el-button
              style="background-color:#3fdbbf; color: #fff;"
              native-type="submit"
            >
              <i class="el-icon-plus"></i> Guardar
            </el-button>
          </b-col>
        </b-row>
      </b-form>
    </el-dialog>

    <el-dialog title="Trasladar" :visible.sync="modalTransfers">
      <b-form @submit="onSubmit2">
        <b-row>
          <b-col xs="12" sm="12" md="8">
            <b-form-group label="Producto:">
              <b-form-input
                v-model="formTransfer.user_business_product_name"
                type="text"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="4">
            <b-form-group label="Cantidad Actual:">
              <b-form-input
                v-model="formTransfer.user_business_product_quantity"
                type="number"
                min="0.00"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="8">
            <b-form-group label="Almacén Origen:">
              <b-form-select
                v-model="formTransfer.establishment_origin_id"
                :options="stablishments"
                required
                value-field="id"
                text-field="name"
                disabled
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="4">
            <b-form-group label="Cantidad a trasladar:">
              <b-form-input
                v-model="formTransfer.quantity"
                type="number"
                min="0.00"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="12">
            <b-form-group label="Almacén Destino:">
              <b-form-select
                v-model="formTransfer.establishment_destiny_id"
                :options="stablishments"
                required
                value-field="id"
                text-field="name"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="12" class="text-right">
            <el-button
              style="background-color:#3fdbbf; color: #fff;"
              native-type="submit"
            >
              <i class="el-icon-plus"></i> Guardar
            </el-button>
          </b-col>
        </b-row>
      </b-form>
    </el-dialog>

    <el-dialog title="Movimientos" :visible.sync="modalMovements">
      <el-table :data="getTableData" stripe fit style="width: 100%">
        <el-table-column type="index" :index="indexMethod"> </el-table-column>
        <el-table-column prop="description" label="Descripción" width="400">
        </el-table-column>
        <el-table-column prop="quantity" label="Cantidad" width="100">
        </el-table-column>
        <el-table-column
          prop="created_at"
          label="Fecha"
          :formatter="formatDate"
          width="180"
        >
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10]"
        :page-size="pageSize"
        :total="totalItems"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import TopBarTitleComponent from "../../../components/TopBarTitle";
import InputSearchComponent from "../../../components/InputSearch";
import SelectOrderByComponent from "../../../components/SelectOrderBy";
import LoadingSkeletonTable from "@/components/Animations/Loading/SkeletonTable";
import catalogueService from "../../services/catalogueService";
import WrapperCatalogTourComponent from "../WrapperTour";
import EmptyPage from "../../../components/EmptyPage";
import Pagination from "@/components/General/Pagination.vue";
import { emptyMedia2 } from "@/services/resources";
import tradeService from "../../../profile/services/tradeService";
import SelectProductsComponent from "../../../components/SelectProducts";

export default {
  name: "MovementListComponent",
  data: () => ({
    filters: {
      search: null,
      page: 1
    },
    pagination: null,
    emptyMedia2,
    apiResponse: {
      success: false
    },
    orderByQuery: null,
    products: [],
    modalStatus: false,
    modalTransfers: false,
    modalMovements: false,
    stocks: [],
    form: {
      user_business_product_id: null,
      user_business_product_name: null,
      quantity: null,
      establishment_id: null,
      reason_transfer: ""
    },
    formTransfer: {
      user_business_product_id: null,
      user_business_product_name: null,
      user_business_product_quantity: null,
      quantity: null,
      establishment_origin_id: null,
      establishment_destiny_id: null
    },
    stablishments: [],
    type: "input",
    movementsData: [],
    currentPage: 1,
    pageSize: 5
  }),
  computed: {
    expandedProducts() {
      const expandedArray = [];

      this.products.forEach(item => {
        item.stocks.forEach(stock => {
          expandedArray.push({
            id: item.id,
            name: item.name,
            code: item.code,
            establishment: stock.establishment,
            establishment_id: stock.establishment_id,
            quantity: stock.quantity,
            details: stock.details
          });
        });
      });

      return expandedArray;
    },
    resultQueryProducts() {
      let products = this.expandedProducts;
      return products.concat().sort((a, b) => {
        switch (this.orderByQuery) {
          case "recent":
            return a.id < b.id ? 1 : -1;
          case "old":
            return a.id > b.id ? 1 : -1;
          case "a-z":
            return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
          case "z-a":
            return a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 1;
        }
      });
    },
    getTableData() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.movementsData.slice(start, end);
    },
    totalItems() {
      return this.movementsData.length;
    }
  },
  methods: {
    toggleApiResponse(property, value) {
      this.apiResponse[property] = value;
    },
    async onSubmit(event) {
      event.preventDefault();
      if (!this.form.user_business_product_id) {
        const message = "No ha seleccionado un producto";
        return this.$notifications.error({ message });
      }

      const isInput = this.type === "input";
      const operationService = isInput
        ? catalogueService.addInputProduct
        : catalogueService.addOutputProduct;

      this.$store.dispatch("toggleRequestLoading");
      try {
        const response = await operationService(this.form);
        const { success, message } = response.data;
        this.modalStatus = false;
        this.handleGetProducts();
        if (success) {
          this.form = {
            user_business_product_id: null,
            user_business_product_name: null,
            quantity: null,
            establishment_id: null,
            reason_transfer: ""
          };
          this.$notifications.success({ message });
        }
      } catch (error) {
        return false;
      } finally {
        this.$store.dispatch("toggleRequestLoading");
      }
    },
    async onSubmit2(event) {
      event.preventDefault();
      try {
        this.$store.dispatch("toggleRequestLoading");

        const response = await catalogueService.moveProduct(this.formTransfer);
        const { success, message } = response.data;

        this.modalTransfers = false;
        this.handleGetProducts();

        if (success) {
          this.formTransfer = {
            user_business_product_id: null,
            user_business_product_name: null,
            user_business_product_quantity: null,
            quantity: null,
            establishment_origin_id: null,
            establishment_destiny_id: null
          };
          this.$notifications.success({ message });
        }
      } catch (error) {
        return false;
      } finally {
        this.$store.dispatch("toggleRequestLoading");
      }
    },
    onChangeSearch({ search }) {
      this.filters.search = search;
      this.handleGetProducts();
    },
    onSelectOrderBy({ orderBy }) {
      this.orderByQuery = orderBy;
    },
    onChangePaginationPage(page) {
      this.filters.page = page;
      this.handleGetProducts();
    },
    async handleGetProducts() {
      const { page, search } = this.filters;

      try {
        let response = await catalogueService.getProducts({
          params: {
            page,
            search
          }
        });
        const { products, pagination } = response?.data;

        if (products) {
          this.products = products;
          this.pagination = pagination;
          this.toggleApiResponse("success", true);
        }
      } catch (error) {
        return false;
      }
    },
    showModal(name, data = null) {
      if (name === "inputs") {
        this.type = "input";
        this.modalStatus = true;
      } else if (name === "outputs") {
        this.type = "output";
        this.modalStatus = true;
      } else if (name === "transfer") {
        this.formTransfer.user_business_product_id = data.id;
        this.formTransfer.user_business_product_name = data.name;
        this.formTransfer.user_business_product_quantity = data.quantity;
        this.formTransfer.establishment_origin_id = data.establishment_id;
        this.modalTransfers = true;
      } else {
        this.movementsData = data;
        this.modalMovements = true;
      }
    },
    onSelectProduct({ product }) {
      this.form.user_business_product_id = product.id;
      this.form.user_business_product_name = product.name;
    },
    async getStablishments() {
      try {
        const response = await tradeService.getStablishments();
        const establishmentsData = response.data?.data || [];
        this.stablishments = establishmentsData;
      } catch (error) {
        return false;
      }
    },
    indexMethod(index) {
      return index + 1;
    },
    formatDate(row) {
      return new Date(row.created_at).toLocaleString("es-ES", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit"
      });
    },
    // Método para manejar el cambio de tamaño de página
    handleSizeChange(size) {
      this.pageSize = size;
    },
    // Método para manejar el cambio de página actual
    handleCurrentChange(page) {
      this.currentPage = page;
    }
  },
  components: {
    TopBarTitleComponent,
    InputSearchComponent,
    SelectOrderByComponent,
    LoadingSkeletonTable,
    WrapperCatalogTourComponent,
    EmptyPage,
    Pagination,
    SelectProductsComponent
  },
  mounted() {
    this.getStablishments();
    this.handleGetProducts();
  }
};
</script>

<style scoped lang="scss">
.btn-primary-custom {
  background-color: #3fdbbf;
  color: #fff;
  border: none;

  &:hover {
    background-color: #88e0d0 !important;
  }
}

.table-items {
  .table-items-body {
    .table-items-content {
      .item-col-code {
        width: 100%;
        text-align: center;
      }
      .table-col-item {
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
