<template>
  <TransitionBounceComponent>
    <CatalogTourComponent v-if="showTour && isCatalogTourCompleted" />
  </TransitionBounceComponent>
</template>

<script>
import TransitionBounceComponent from "@/components/Transitions/Bounce";
import CatalogTourComponent from "./Tour";

export default {
  name: "WrapperCatalogTourComponent",
  data() {
    return {
      showTour: false
    };
  },
  computed: {
    isCatalogTourCompleted() {
      const { user_config_general } = this.$store.getters.getUser;
      return (
        user_config_general && !user_config_general.is_catalog_tour_completed
      );
    }
  },
  methods: {},
  components: {
    TransitionBounceComponent,
    CatalogTourComponent
  },
  mounted() {
    setTimeout(() => {
      if (this.isCatalogTourCompleted) this.showTour = true;
    }, 1000);
  }
};
</script>

<style lang="scss" scoped></style>
